var subtitles__1 = [
	// { "start": "0", "end": "1.9", "text": "Raumgeräusche" },
	// { "start": "0", "end": "7.9",	"text": "Leise, dumpfe Mikrophongeräusche" },

	{ "start": "0", "end": "5.9", "text": "Raumgeräusche, Leise, dumpfe Mikrophongeräusche" },
	
	{ "start": "6", "end": "30", "text": "Entfernt ist eine vortragende Stimme zu hören, es geht u.A. um Barrierefreiheit: „Im deutschen Wort Barrierefreiheit steckt schon die Barriere und die Freiheit gleichzeitig drin, und da muss man sich natürlich fragen, wessen Freiheit ist da eigentlich gemeint, also wessen Freiheit ist die Norm?“ " },
	
	{ "start": "31", "end": "41.9", "text": "Schritte durch den Raum kommen gleichbleibend näher" },
	
	{ "start": "42", "end": "50.9", "text": "Stimmen" },
	
	{ "start": "51", "end": "55.9", "text": "Mikrophon-Feedback, es brummt ein wenig am Ohr" },
	
	{ "start": "56", "end": "65.5", "text": "Eine Computerstimme spricht unverständlich" },
	
	{ "start": "66", "end": "75.9", "text": "Ein Stuhl rollt, eine Türe quietscht" },
	
	{ "start": "76", "end": "92", "text": "Die Stimme wird wieder deutlicher, es geht um Jahresthemen, das Haus, Steuergelder" },
	
	{ "start": "93", "end": "96", "text": "Wechsel nach Draußen, fahrende Autos" },
	
	{ "start": "97", "end": "98.9", "text": "Knacken" },
	
	{ "start": "99", "end": "100", "text": "Schnitt" },
	
	{ "start": "100.5", "end": "111.5", "text": "Zurück zur Stimme im Raum. Man hört, dass sich viele Personen in dem Gebäude befinden" },
	
	{ "start": "112", "end": "114", "text": "Eine Stimme spricht deutlich durch ein Walkie-Talkie:  „Hallo Hallo“" },
	
	{ "start": "119", "end": "125", "text": "„Hallo Hallo“" },

	{ "start": "126", "end": "129", "text": "„Hallo, hören Sie mich?“" },

	{ "start": "130", "end": "135", "text": "Die vortragende Stimme erwähnt sensuelle Impulse und die neue Einzelausstellung in der Kirche mit Candice Lin " },

	{ "start": "136", "end": "140", "text": "Brummen" },

	{ "start": "141", "end": "143", "text": "Walkie-Talkie: „...durch den Flur“ " },

	{ "start": "146", "end": "160", "text": "Schritte aus verschiedenen Richtungen, schnellere Schritte überlagern langsamere Schritte, unterschiedliche Sohlen und Absätze" },

	{ "start": "161", "end": "179", "text": "Ein Telefongespräch blendet sich ein: „Hallo, jetzt warst Du grade weg“ – „Oh, ok, hörst Du mich jetzt?“ – „Ah ja, ja, es war irgendwie gestört“ – unterbrochene Satzfragmente, im Hintergrund Stimmengemurmel" },

	{ "start": "182", "end": "192", "text": "„Ja, hörst Du mich?“ – Störungsgeräusche – „Ja ich...“" },

	{ "start": "193", "end": "205", "text": "Schritte, Mikrophon-Feedbacks" },

	{ "start": "204", "end": "222", "text": "Schritte, Kopierergeräusche, Gebläse" },

	{ "start": "225", "end": "227", "text": "Klopfen" },

	{ "start": "228", "end": "240", "text": "Stimme auf Lautsprecher im Hintergrund: „Hi, ich wollte mich nochmal melden wegen der Bilder und Dateien, die ihr für die Grafikerinnen braucht…“" },

	{ "start": "241", "end": "255", "text": "Stimmen, Schritte, Türschloß, Schlüsselgeklapper" },

	{ "start": "256", "end": "258", "text": "Aneinander vorbeilaufende Personen: „Moin“ – „Hallo Guten Morgen“" },

	{ "start": "259", "end": "287", "text": "Schlüsselgeklapper. Tür fällt mehrmals ins Schloß und wird mehrfach verriegelt" },

	{ "start": "288", "end": "287", "text": "Schritte, Stimme läuft an Mikrophon vorbei und sagt: „Eins Zwei Eins Zwei“" },

	{ "start": "298", "end": "303", "text": "Erneut: Schritte, Stimme läuft an Mikrophon vorbei und sagt: „Eins Zwei Eins Zwei“" },

	{ "start": "304", "end": "302", "text": "Stimmen einer Gruppe von Leuten im Gespräch" },

  ];